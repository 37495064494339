import React from 'react'
import styled from 'styled-components'
import theme from '../theme/theme'
import GridList from './GridList'

type Item = {
  text: string
  title: string
}

interface CardProps {
  item: Item
}

const Card: React.FC<CardProps> = ({ item }) => {
  return (
    <CardContainer>
      <CardTitle>{item.title}</CardTitle>
      {item.text}
    </CardContainer>
  )
}

interface BusinessModelGridProps {
  items: Item[]
}

const BusinessModelGrid: React.FC<BusinessModelGridProps> = ({ items }) => {
  return (
    <GridList>
      {items.map((item, index) => {
        return <Card key={index} item={item} />
      })}
    </GridList>
  )
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 24px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${theme.colors.secondary};
  max-width: 200px;
  padding: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: unset;
  }
`

const CardTitle = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`

export default BusinessModelGrid
