export type BusinessModelItem = {
  title: string
  text: string
}

export type BusinessModelItems = BusinessModelItem[]

const services: BusinessModelItems = [
  {
    title: 'Réparation Hors Garantie',
    text: "Notre offre principale est orientée vers les clients finaux : la réparation hors garantie ou en exclusion, notamment les produits cassé. Diagnostic et devis immédiat, réparation sur place en moins d'une heure, dans 82% des cas.",
  },
  {
    title: 'Réparation Sous Garantie',
    text: 'Save est le partenaire privilégié des constructeurs (Samsung, Huawei, Xiaomi). Dans cette démarche, ceux-ci proposent des agréments permettant la réparation de produits sous garantie (en panne, réparation à la charge du constructeur).',
  },
  {
    title: 'Réparation Sous Assurance',
    text: "Save a conclu des partenariats importants avec des courtiers et assureurs qui permettent l'orientation vers notre réseau de franchisés de flux de réparation (principalement des sinistres couverts pas une garantie casse).",
  },
  {
    title: 'Vente\n Produits & Services',
    text: "Nous proposons une gamme très complète de produits reconditionnés (smartphones principalement), d'accessoires et de services (sauvegarde et transfert de données, certification, etc). Save dispose également d'outils de découpe et de pose de films de protection.",
  },
  {
    title: 'Rachat  & recyclage',
    text: 'Afin d’alimenter notre stock de produits reconditionnés mais également sur tous types de produits électroniques nomades, Save propose le rachat immédiat. Nous nous appuyons sur des partenaires spécialisés pour la valorisation et la revente des produits',
  },
]

export default services
