import styled from 'styled-components'

const ContactFormContainer = styled.div`
  display: grid;
  gap: 10px;
  padding: 12px 8px;
  background-color: white;

  // subject
  & > div:nth-child(5) {
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 15px;
    }
  }

  // submit
  & > *[class^='Form__Button'] {
    margin: 0 auto;
    width: 100%;
    max-width: 140px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    padding: 14px 39px;
    grid-template-areas:
      'lastname email'
      'firstname phone'
      'subject subject'
      'message message'
      'options options'
      'submit submit';

    & > div:nth-child(1) {
      grid-area: lastname;
    }

    & > div:nth-child(2) {
      grid-area: firstname;
    }

    & > div:nth-child(3) {
      grid-area: email;
    }

    & > div:nth-child(4) {
      grid-area: phone;
    }

    & > div:nth-child(5) {
      grid-area: subject;
    }

    & > div:nth-child(6) {
      grid-area: message;
    }

    & > *[class^='Form__Options'] {
      grid-area: options;
      width: 100%;
      max-width: 433px;
      margin: 10px auto 0 auto;
    }

    & > *[class^='Form__SubmitContainer'] {
      grid-area: submit;
      margin: 15px auto 5px auto;
    }
  }
`

export default ContactFormContainer
