import styled from 'styled-components'

const ContactContainer = styled.div`
  padding: 0 12px 20px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 25px;
    padding: 0 12px 30px 12px;
  }

  & > *:nth-child(2) {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: unset;
    }
  }

  & > *:nth-child(3) {
    margin-top: 15px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 40px;
      max-width: 687px;
    }
  }

  & > *:nth-child(4) {
    max-width: 307px;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 687px;
    }
  }
`

export default ContactContainer
