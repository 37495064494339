import React from 'react'
import styled from 'styled-components'

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;

  // column-gap: 20px;
  row-gap: 20px;
`

const GridWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 15px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 21px;
    padding-bottom: 25px;
  }
`
interface Props {
  children?: React.ReactNode
}

const GridList: React.FC<Props> = ({ children }) => (
  <GridWrapper>
    <List>{children}</List>
  </GridWrapper>
)

export default GridList
