export type IdentityItem = {
  title: string
  text: string
  image: string
  url?: string
}

export type IdentityItems = IdentityItem[]

const services: IdentityItems = [
  {
    title: 'Mission',
    image: '/images/identity_save/mission.png',
    text: 'Soutenir une mobilité high tech responsable',
  },
  {
    title: 'Ambition',
    image: '/images/identity_save/ambition.png',
    text: 'Devenir leader Européen de la réparation et de la réutilisation',
  },
  {
    title: 'Finalité',
    image: '/images/identity_save/finalite.png',
    text: 'Réparer plus pour recycler moins et ne plus jeter',
  },
  {
    title: 'Valeurs',
    image: '/images/identity_save/valeurs.png',
    text: 'Customer Centric Rigueur & Exigence Proactivité & Collectif',
  },
]

export default services
