import { ContactFormData, ContactType } from '../models/ContactFormData'
import * as yup from 'yup'

const types: ContactType[] = [
  'journaliste',
  'client',
  'franchise',
  'recrutement',
  'other',
]
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const contactFormSchema: yup.SchemaOf<ContactFormData> = yup
  .object({
    type: yup
      .mixed<ContactType>()
      .oneOf(types)
      .required('Le type de contact est requis'),
    lastname: yup.string().required('Un nom de famille est requis'),
    firstname: yup.string().required('Un prénom est requis'),
    email: yup.string().email().required('Une adresse mail est requise'),
    phone: yup
      .string()
      .matches(phoneRegExp, 'Le format de numéro de téléphone n‘est pas valide')
      .required('Un numéro de téléphone est requis'),
    subject: yup.string().required('Un sujet est requis'),
    message: yup.string().required('Un message est requis'),
    newsletter: yup.boolean(),
    files: yup
      .mixed()
      .test('', (value, ctx: { parent: ContactFormData; path: string }) => {
        // must be to undefined if type is not recruitment
        if (ctx.parent.type !== 'recrutement') {
          return value === undefined
        }

        if (!(value instanceof FileList) || value.length === 0) {
          return new yup.ValidationError(
            'Une pièce jointe est requise',
            value,
            ctx.path,
            'required'
          )
        }

        if (value.length > 2) {
          return new yup.ValidationError(
            'Seule deux fichiers joints maximum sont acceptés',
            value,
            ctx.path,
            'required'
          )
        }

        return true
      }),
  })
  .defined()

export default contactFormSchema
