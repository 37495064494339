export type PartnersItem = {
  title: string
  image: string
}

export type PartnersItems = PartnersItem[]

const partners: PartnersItems = [
  {
    title: 'huawei',
    image: '/images/partenaires/huawei-bg.png',
  },
  {
    title: 'bouygues',
    image: '/images/partenaires/bouygues-bg.png',
  },
  {
    title: 'samsung',
    image: '/images/partenaires/samsung-bg.png',
  },
  {
    title: 'orange',
    image: '/images/partenaires/orange-bg.png',
  },
  {
    title: 'backmarket',
    image: '/images/partenaires/backmarket-bg.png',
  },
  {
    title: 'xiaomi',
    image: '/images/partenaires/xiaomi-bg.png',
  },
  {
    title: 'rhinoshield',
    image: '/images/partenaires/rhinoshield-bg.png',
  },
]

export default partners
