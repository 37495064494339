export type ProfileItem = {
  title: string
  text: string
  image: string
}

export type ProfileItems = ProfileItem[]

const services: ProfileItems = [
  {
    title: "Droits d'entrée",
    image: '/images/profile_save/droits-entree.png',
    text: '20 000€\n\n\n\xA0 ',
  },
  {
    title: 'Redevance',
    image: '/images/profile_save/redevance.png',
    text: 'Suivant CA : de 9,5 à 5,5%\n Marketing : 600€ par trimestre',
  },
  {
    title: 'Concept Magasin',
    image: '/images/profile_save/concept-magasin.png',
    text: 'Entre 20 000 et\n40 000€\n\n\xA0 ',
  },
  {
    title: 'Outils & Stock',
    image: '/images/profile_save/outils-stock.png',
    text: 'Entre 15 000 et\n20 000€\n\n\xA0 ',
  },
]

export default services
