import React from 'react'
import styled from 'styled-components'
import GridList from './GridList'
import LinkExtended from './LinkExtended'

type Item = {
  text: string
  title: string
  image: string
  url?: string
}

interface CardProps {
  item: Item
}

const Card: React.FC<CardProps> = ({ item }) => {
  return (
    <CardContainer to={item.url!}>
      <CardImage src={item.image} alt={item.title} />
      <CardTextContainer>
        <CardTitle>{item.title}</CardTitle>
        {item.text}
      </CardTextContainer>
    </CardContainer>
  )
}

interface FranchiseGridProps {
  items: Item[]
}

const FranchiseGrid: React.FC<FranchiseGridProps> = ({ items }) => {
  return (
    <GridList>
      {items.map((item, index) => {
        return <Card key={index} item={item} />
      })}
    </GridList>
  )
}

const CardContainer = styled(LinkExtended)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  max-width: 150px;
  text-decoration: none;
  min-width: 126px;
`

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 9px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: white;
`

const CardTitle = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`

const CardImage = styled.img`
  width: 50px;
  height: 50px;
`

export default FranchiseGrid
