import React from 'react'
import styled from 'styled-components'
import theme from '../theme/theme'

type Item = {
  image: string
  title: string
}

interface CardProps {
  item: Item
}

const Card: React.FC<CardProps> = ({ item }) => {
  return (
    <CardContainer>
      <CardImage src={item.image} alt={item.title} />
    </CardContainer>
  )
}

interface PartnersGridListProps {
  items: Item[]
}

const PartnersGridList: React.FC<PartnersGridListProps> = ({ items }) => {
  return (
    <Container>
      <List>
        {items.map((item, index) => {
          return <Card key={index} item={item} />
        })}
      </List>
    </Container>
  )
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.secondary};
`

const CardImage = styled.img`
  height: 65px;
  max-width: 200px;
  object-fit: contain;
`

const Container = styled.div`
  background-color: white;
  padding: 17px 17px 22px 17px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 45px 17px 34px 17px;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  margin: 0 auto;
  gap: 7px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 14px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 14px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    max-width: 682px;
  }
`

export default PartnersGridList
